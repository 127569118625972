<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    @before-open="beforeOpen"
    :name="modalName"
  >
    <div class="modal--wrapper">
      <div class="modal--title">
        {{ $t('purchaseDocument.modal.title') }}
      </div>
      <simple-photo-preview :photos="purchaseDocumentPhotos"></simple-photo-preview>
      <div
        class="d-flex align-center justify-center mt-4"
        v-if="purchaseDocument && purchaseDocument.status === 'VERIFYING'"
      >
        <v-btn color="error" class="bottom--button" @click="reject">
          {{ $t('purchaseDocument.btn.reject') }}
        </v-btn>
      </div>
      <div
        class="d-flex align-center justify-center mt-4"
        v-if="purchaseDocument && purchaseDocument.status === 'VERIFYING'"
      >
        <v-btn color="primary" class="bottom--button" @click="accept">
          {{ $t('purchaseDocument.btn.accept') }}
        </v-btn>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const SimplePhotoPreview = () => import('@/components/utils/simple-photo-preview');

export default {
  name: 'modal-purchase-document',
  mixins: [HelperMixin],
  components: {
    SimplePhotoPreview,
  },
  data() {
    return {
      modalName: 'modal-purchase-document',
      purchaseDocument: null,
    };
  },
  computed: {
    ...mapState({
      purchaseDocumentPhotos: (state) => state.transaction.form.purchaseDocumentPhotos,
      loading: (state) => state.transaction.form.loading,
    }),
  },
  methods: {
    beforeOpen(event) {
      this.purchaseDocument = event.params.purchaseDocument;
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    async reject() {
      await this.$store.dispatch('transaction/form/rejectPurchaseDoc');
      await this.$swal(
        this.$i18n.t('general.success'),
        this.$i18n.t('transaction.rejectPurchaseDoc.successMsg'),
        'success',
      );
      this.close();
      await this.$store.dispatch('transaction/getDetailInitData', this.$route.params.uuid);
    },
    async accept() {
      await this.$store.dispatch('transaction/form/acceptPurchaseDoc');
      await this.$swal(
        this.$i18n.t('general.success'),
        this.$i18n.t('transaction.acceptPurchaseDoc.successMsg'),
        'success',
      );
      this.close();
      await this.$store.dispatch('transaction/getDetailInitData', this.$route.params.uuid);
    },
  },
};
</script>

<style scoped></style>
